import React from 'react'
import Link from 'next/link'
import FooterNavData from '../../data/footerNav.remotejson'

export default function FooterNav () {
  const renderFooterNavOne = items =>
    FooterNavData.items.map(item => {
      return (
        <li key={item.ID}>
          {!item.slug ? (
            <a
              href={item.url}
              target='_blank'
              rel='noopener'
              dangerouslySetInnerHTML={{ __html: item.title }}
            ></a>
          ) : (
            <Link as={`/${item.slug}`} href='/[slug]' passHref>
              <a dangerouslySetInnerHTML={{ __html: item.title }}></a>
            </Link>
          )}
        </li>
      )
    })
  //console.log(FooterNavData.items)
  return (
    <React.Fragment>
      <nav aria-label='Secondary' className='nav-up'>
        <div className='menu-footer-nav-1-container'>
          <ul id='menu-footer-nav-1' className='menu'>
            {renderFooterNavOne()}
          </ul>
        </div>
        {/*.menu-footer-nav-1-container*/}
      </nav>
    </React.Fragment>
  )
}
