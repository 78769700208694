import React, { useState } from 'react'

function usePlayState () {
  const [play, setPlay] = useState(false)
  return [play, () => setPlay(!play)]
}

const HeroFullVideo = props => {
  const [play, togglePlay] = usePlayState()

  const {
    title = 'HTML Title<br>Content',
    body = 'Body content.',
    heroTitle,
    heroBody,
    bgImage = 'https://placekitten.com/1920/1080',
    bgVideo = 'https://player.vimeo.com/video/452174803',
    minHeight = '400px'
  } = props

  return (
    <React.Fragment>
      <div className='video-background'>
        <div className='play-container d-flex align-items-center'>
          <div className='video-background--intro'>
            <h1
              className='heading1'
              dangerouslySetInnerHTML={{ __html: heroTitle }}
            />

            <p
              className='heading3'
              dangerouslySetInnerHTML={{ __html: heroBody }}
            />

            <button className='video-play-button' onClick={togglePlay}>
              <span className='sr-only'>Play Documentary</span>
            </button>
          </div>
          {/*.video-background--intro*/}
        </div>
        {/*.play-container */}

        <div
          className={`video-background--videoWrapper ${
            play ? 'videoWrapper-active' : ''
          }`}
        >
          <div className='video-background--video'>
            <button
              id='video-close'
              type='button'
              className={`close ${play ? 'close-active' : ''}`}
              onClick={togglePlay}
            >
              <img
                src='/public-next/images/close.svg'
                width='30'
                height='30'
                alt='Close Video'
              />
            </button>

            <div className='embed-container'>
              <iframe
                src={bgVideo}
                aria-label='Documentary Video'
                frameBorder='0'
                webkitallowfullscreen='true'
                mozallowfullscreen='true'
                allowFullScreen={true}
              />
            </div>
            {/*.embed-container*/}
          </div>
          {/*.video-bakcground--video*/}
        </div>
        {/*.video-background--videoWrapper*/}

        <script src='https://player.vimeo.com/api/player.js'></script>
      </div>
      {/*.video-background*/}

      <style jsx>
        {`
          .play-container {
            background-image: url(${bgImage});
          }

          .videoWrapper-active {
            display: flex !important;
            visibility: visible;
            opacity: 1;
          }

          .close-active {
          }
        `}
      </style>
    </React.Fragment>
  )
}

export default HeroFullVideo
