import Link from 'next/link'

const HeroFullbleed = props => {
  const {
    title = 'HTML Title<br>Content',
    body = 'Body content.',
    bottomLeft = false,
    heroTitle,
    heroBody,
    bgImage = 'https://placekitten.com/1920/1080',
    minHeight = '400px',
    left = false,
    disableArrow = false,
    hideDate
  } = props

  return (
    <>
      <div
        className={`${
          props.centered ? 'centered mobileOnDesktop' : ''
        } animate-intro container-fluid intro__fullbleed`}
      >
        <div
          className={`row ${bottomLeft ? 'bottom-left' : 'align-items-center'}`}
        >
          <div className='col-12 intro__mobileimg d-lg-none'></div>
          {/*.col-12 .intro__mobileimg*/}

          <div
            className={`slide-left-intro intro__callout-text col-10 col-lg-6 offset-1 ${
              left
                ? 'offset-lg-0 flex-column'
                : props.centered
                ? ''
                : bottomLeft
                ? 'offset-lg-0 bottom-left'
                : 'offset-lg-6 flex-column'
            } d-flex  justify-content-center `}
          >
            <div className='bluebox'>
              <h1
                className='heading2 intro__heading'
                dangerouslySetInnerHTML={{
                  __html: heroTitle ? heroTitle : title
                }}
              />

              {!hideDate ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: heroBody ? heroBody : body
                  }}
                />
              ) : (
                ''
              )}

              {!disableArrow && (
                <a
                  role='button'
                  aria-label='Scroll to next section'
                  className='intro__link link--scroll'
                  href='#skip'
                >
                  <img
                    alt='Down Arrow'
                    className='link__icon'
                    src='/public-next/images/down-arrow.png'
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .intro__fullbleed,
        .intro__fullbleed .intro__mobileimg {
          background-image: url(${bgImage});
        }

        .intro__fullbleed {
          min-height: ${minHeight};
        }
      `}</style>
    </>
  )
}

export const BGIMAGE = 'url(https://via.placeholder.com/960x790.png)'
export const MINHEIGHT = '100px'
export default HeroFullbleed
