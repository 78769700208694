import React from 'react'
import Link from 'next/link'

import SocialNav from './SocialNav'
import FooterNav from './FooterNav'
import LazyImage from '../LazyImage'
import Subscribe from '../Subscribe'
import SubscribeScript from '../SubscribeScript'

import OptionsData from '../../data/acfOptions.remotejson'

const Footer = React.memo(props => {
  const data = OptionsData.acf
  const primarySeal = OptionsData.acf.footer_seal_image
  const secondarySeal = OptionsData.acf.secondary_footer_seal_image
  return (
    <footer>
      {/*Hidden div called with aria-describedby to let screen readers know link will open in a new tab*/}
      <div hidden>
        <span id='new-tab'>Opens external site in a new tab</span>
      </div>
      <div className='footer-top container-fluid'>
        <div className='col-10 offset-1'>
          <div className='row footer__border'>
            <div className='col-md-5 footer-left'>
              <div
                className='textwidget custom-html-widget'
                dangerouslySetInnerHTML={{
                  __html: OptionsData.acf.footer_blurb
                }}
              />

              {/* .text-widget*/}
            </div>
            {/*.col-md-5 */}

            <div className='col-md-6 offset-md-1 footer-left'>
              <hr />
              <div className='footer-top__links'>
                <FooterNav />

                <SocialNav />
              </div>
              <div className='footer-form'>
                <Subscribe /> 
                {/* script for form linked before footer close */}
              </div>
              {/*.footer-top__links*/}
            </div>
            {/*.col-md-6*/}
          </div>
          {/*.row .footer__border*/}
        </div>
        {/*.col-10*/}
      </div>
      {/*.footer-top*/}

      <div className='footer-bottom container-fluid'>
        <div className='col-md-10 offset-md-1'>
          <div className='row'>
            <div className='col-lg-6 footer-left footer-flex'>
              <ul className='footer-bottom__links'>
                {primarySeal ? (
                  <li>
                    <a
                      aria-label={`${data.footer_seal_aria}'s Site`}
                      aria-describedby='new-tab'
                      className=''
                      target='_blank'
                      rel='noopener'
                      href={data.footer_seal_url}
                    >
                      <LazyImage
                        imageClass='footer-logo'
                        alt={primarySeal.alt}
                        src={primarySeal.url}
                      />
                    </a>
                  </li>
                ) : (
                  ''
                )}

                {secondarySeal ? (
                  <li>
                    <a
                      aria-label={`${data.secondary_footer_seal_aria}'s Site`}
                      aria-describedby='new-tab'
                      className=''
                      target='_blank'
                      rel='noopener'
                      href={data.secondary_footer_seal_url}
                    >
                      <img
                        className='footer-logo'
                        alt={secondarySeal.alt}
                        src={secondarySeal.url}
                      />
                    </a>
                  </li>
                ) : (
                  ''
                )}

                <li>
                  <a href='/privacy-policy'>Privacy Policy</a>

                  <span className='footer-divider'>|</span>
                </li>
                <li>
                  <a href='/terms-of-use'>Terms & Conditions</a>
                </li>
              </ul>
              {/*footer-bottom__links*/}
            </div>
            {/*col-lg-6*/}

            <div className='col-lg-6 footer-bottom--flex footer-left'>
              <div className='footer-bottom__copyright'>
                &copy; {new Date().getFullYear()} The Quell Foundation
                <span className='footer-divider'>|</span>All rights reserved
              </div>
              <SocialNav bottom />
            </div>
            {/*col-lg-6*/}
          </div>
          {/*.row*/}
        </div>
        {/*col-md-10*/}
      </div>
      {/* .footer-bottom*/}
      <SubscribeScript/>
    </footer>
  )
})

export async function getStaticProps ({ preview = false }) {
  const options = {
    ...(await getOptions())
  }

  return {
    props: {
      options
    }
  }
}

export default Footer
