//This is how you customize head for Next.js
import React from 'react'
import Head from 'next/head'

const IndexPage = props => {
  const {
    title,
    body,
    bgImage,
    bgId,
    alt,
    height,
    width,
    bgVideo,
    videoHeight,
    videoWidth,
    slug
  } = props
  return (
    <div>
      <Head>
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' charSet='utf-8' />
        {title ? (
          <title>
            {title.replace(/(<([^>]+)>)/gi, '').replace(/&#8211;/g, '-') +
              ' - The Quell Foundation' || 'The Quell Foundation'}
          </title>
        ) : (
          <title>{'The Quell Foundation'}</title>
        )}
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=1'
        />

        {slug ? (
          bgVideo ? (
            <meta property='og:url' content={bgVideo} />
          ) : (
            <meta
              property='og:url'
              content={
                'thequellfoundation.org' +
                slug.replace(/\s+/g, '-').toLowerCase()
              }
            />
          )
        ) : (
          <meta property='og:url' content={'thequellfoundation.org'} />
        )}

        {bgVideo ? (
          <meta property='og:type' content='video' />
        ) : (
          <meta property='og:type' content='website' />
        )}

        {title ? (
          <meta
            property='og:title'
            content={title
              .replace(/(<([^>]+)>)/gi, '')
              .replace(/&#8211;/g, '-')}
            key={'key' + title}
          />
        ) : (
          <meta
            property='og:title'
            content='The Quell Foundation'
            key={'key' + title}
          />
        )}
        {body ? (
          <React.Fragment>
            <meta
              name='description'
              content={body
                .replace(/(<([^>]+)>)/gi, '')
                .replace(/&nbsp;/g, ' ')
                .replace(/\[/g, '')
                .replace(/Read More&#8230;/g, '')
                .replace(/&#8230;]/g, '')
                .replace(/&#8216;/g, "'")
                .replace(/&#8217;/g, "'")
                .replace(/&#8211;/g, '-')}
            />
            <meta
              property='og:description'
              content={body
                .replace(/(<([^>]+)>)/gi, '')
                .replace(/&nbsp;/g, ' ')
                .replace(/\[/g, '')
                .replace(/Read More&#8230;/g, '')
                .replace(/&#8230;]/g, '')
                .replace(/&#8216;/g, "'")
                .replace(/&#8217;/g, "'")
                .replace(/&#8211;/g, '-')}
            />
          </React.Fragment>
        ) : (
          ''
        )}

        {bgImage ? (
          <React.Fragment>
            <meta property='og:image' content={bgImage} />
            <meta property='og:image:secure_url' content={bgImage} />
            <meta property='og:image:type' content='image/jpeg' />

            {alt ? <meta property='og:image:alt' content={alt} /> : ''}

            {height ? <meta property='og:image:height' content={height} /> : ''}

            {width ? <meta property='og:image:width' content={width} /> : ''}

            <meta name='twitter:image' content={bgImage} />
          </React.Fragment>
        ) : (
          ''
        )}

        <meta name='twitter:card' content='summary_large_image' />
        <meta property='twitter:domain' content='thequellfoundation.org' />

        {slug ? (
          <meta
            property='twitter:url'
            content={
              'thequellfoundation.org' + slug.replace(/\s+/g, '-').toLowerCase()
            }
          />
        ) : (
          <meta property='twitter:url' content={'thequellfoundation.org'} />
        )}

        {title ? (
          <meta
            name='twitter:title'
            content={title
              .replace(/(<([^>]+)>)/gi, '')
              .replace(/&#8211;/g, '-')}
            key={'key' + title}
          />
        ) : (
          <meta
            name='twitter:title'
            content='The Quell Foundation'
            key={'key' + title}
          />
        )}

        {body ? (
          <meta
            name='twitter:description'
            content={body
              .replace(/(<([^>]+)>)/gi, '')
              .replace(/&nbsp;/g, ' ')
              .replace(/\[/g, '')
              .replace(/Read More&#8230;/g, '')
              .replace(/&#8230;]/g, '')
              .replace(/&#8211;/g, '-')
              .replace(/&#8216;/g, "'")
              .replace(/&#8217;/g, "'")}
          />
        ) : (
          ''
        )}

        {bgVideo ? (
          <React.Fragment>
            <meta property='og:video' content={bgVideo} />
            <meta property='og:video:secure_url' content={bgVideo} />
            <meta property='og:video:type' content='video' />

            {height ? (
              <meta property='og:video:height' content={videoHeight} />
            ) : (
              ''
            )}

            {width ? (
              <meta property='og:video:width' content={videoWidth} />
            ) : (
              ''
            )}

            <meta name='twitter:video' content={bgVideo} />
            <meta name='twitter:video:src' content={bgVideo} />
          </React.Fragment>
        ) : (
          ''
        )}
      </Head>
    </div>
  );
}

export default IndexPage
